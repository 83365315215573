import React, { useState, useRef, useEffect, useCallback } from "react"
import { ResponsiveBar } from "@nivo/bar"
import { Select, Tooltip } from "antd"
import DataQButton from "../Buttons/DataQButton"
import { toPng } from "html-to-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn } from "@fortawesome/free-solid-svg-icons"

const { Option } = Select
const customColorScheme = [
  "#36c3ed",
  "#4a90e2",
  "#50e3c2",
  "#f5a623",
  "#b8e986",
  "#bd10e0",
  "#9013fe",
  "#417505",
  "#7ed321",
  "#8b572a",
]

/**
 * Custom tooltip component for the bar chart
 * @param {Object} props - The props object
 * @param {string} props.id - The id of the bar
 * @param {number|string} props.value - The value of the bar
 * @param {string} props.color - The color of the bar
 * @param {string} props.indexValue - The index value of the bar
 * @returns {JSX.Element} A custom tooltip component
 */
const CustomTooltip = ({ id, value, color, indexValue }) => (
  <div className="bg-white p-2 rounded-lg">
    <strong style={{ color: "#333" }}>{indexValue}</strong>
    <br />
    <span style={{ color }}>
      {id}: {value}
    </span>
  </div>
)

/**
 * Custom select component with tooltip
 * @param {Object} props - The props object
 * @param {string} props.placeholder - The placeholder text for the select
 * @param {Array} props.value - The current selected values
 * @param {Function} props.onChange - The function to call when selection changes
 * @param {Array} props.options - The options for the select
 * @returns {JSX.Element} A custom select component with tooltip
 */
const CustomSelect = ({ placeholder, value, onChange, options }) => {
  const selected = options.filter((option) => value.includes(option.value))
  const tooltipTitle = selected.map((item) => item.label).join(", ")

  return (
    <Tooltip title={tooltipTitle} placement="bottomLeft">
      <Select
        mode="multiple"
        style={{ width: 200 }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxTagCount={0}
        maxTagPlaceholder={(omittedValues) =>
          `${omittedValues.length} selected`
        }
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Tooltip>
  )
}

/**
 * BarChart component for rendering a customizable bar chart
 * @param {Object} props - The props object
 * @param {Array} props.data - The data for the chart
 * @param {Array} props.keys - The keys to use for the chart
 * @param {Array} props.statisticTypes - The available statistic types
 * @param {string} props.xAxisLabel - The label for the x-axis
 * @param {boolean} props.isChart - Whether to render as a chart or not
 * @returns {JSX.Element} A bar chart component
 */
const BarChart = ({ data, keys, statisticTypes, xAxisLabel, isChart }) => {
  const [selectedStatistic, setSelectedStatistic] = useState(statisticTypes[0])
  const [chartHeight, setChartHeight] = useState(400)
  const [leftMargin, setLeftMargin] = useState(60)
  const [selectedKeys, setSelectedKeys] = useState(
    keys.filter((key) => key !== "Totals")
  )
  const [selectedCategories, setSelectedCategories] = useState(
    data.filter((item) => item.xValue !== "Totals").map((item) => item.xValue)
  )
  const chartRef = useRef(null)

  /**
   * Format the tick value based on the selected statistic
   * @param {number} value - The value to format
   * @returns {string} The formatted value
   */
  const formatTickValue = useCallback(
    (value) => {
      if (
        selectedStatistic.toLowerCase().includes("perc") ||
        selectedStatistic.toLowerCase().includes("percent") ||
        selectedStatistic.toLowerCase().includes("%")
      ) {
        return `${(value * 100).toFixed(2)}%`
      }
      return value.toLocaleString()
    },
    [selectedStatistic]
  )

  useEffect(() => {
    /**
     * Calculate the chart height based on the selected categories
     * @returns {number} The calculated chart height
     */
    const calculateChartHeight = () => {
      const baseHeight = 400
      const xLabelLength = Math.max(
        ...selectedCategories.map((item) => item.length)
      )
      const xItemCount = selectedCategories.length
      const additionalHeight =
        Math.max(xLabelLength * 2 - 20, 0) + Math.max(xItemCount * 10 - 50, 0)
      return baseHeight + additionalHeight
    }

    setChartHeight(calculateChartHeight())

    const longestYLabel = formatTickValue(
      Math.max(
        ...data.flatMap((item) =>
          Object.values(item)
            .filter((val) => typeof val === "object" && val[selectedStatistic])
            .map((val) => val[selectedStatistic])
        )
      )
    )
    const newLeftMargin = Math.max(60, longestYLabel.length * 8 + 40)
    setLeftMargin(newLeftMargin)
  }, [data, selectedStatistic, selectedCategories, formatTickValue])

  if (!data || data.length === 0 || keys.length === 0) {
    return <div>No data available for the chart</div>
  }

  if (!isChart) {
    return (
      <div>Unable to display this output as a chart. Please view as table.</div>
    )
  }

  /**
   * Prepare the chart data based on selected categories and keys
   * @type {Array}
   */
  const chartData = data
    .filter(
      (item) =>
        selectedCategories.includes(item.xValue) || item.xValue === "Totals"
    )
    .map((item) => {
      const newItem = { xValue: item.xValue }
      keys.forEach((key) => {
        if (selectedKeys.includes(key) || key === "Totals") {
          newItem[key] = item[key][selectedStatistic] || 0
        }
      })
      return newItem
    })

  /**
   * Handle change in selected statistic
   * @param {string} value - The newly selected statistic
   */
  const handleStatisticChange = (value) => {
    setSelectedStatistic(value)
  }

  /**
   * Handle change in selected keys
   * @param {Array} values - The newly selected keys
   */
  const handleKeysChange = (values) => {
    setSelectedKeys(values)
  }

  /**
   * Handle change in selected categories
   * @param {Array} values - The newly selected categories
   */
  const handleCategoriesChange = (values) => {
    setSelectedCategories(values)
  }

  /**
   * Export the chart as a PNG image
   */
  const exportChart = () => {
    if (chartRef.current) {
      toPng(chartRef.current, { quality: 0.95 })
        .then((dataUrl) => {
          const link = document.createElement("a")
          link.download = "chart.png"
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.error("Error exporting chart:", err)
        })
    }
  }

  return (
    <div className="w-full mt-5">
      <div className="flex flex-wrap items-end gap-4 mb-5">
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-semibold">Statistic Type</label>
          <Select
            className="w-48"
            value={selectedStatistic}
            onChange={handleStatisticChange}
          >
            {statisticTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-semibold">Variables</label>
          <CustomSelect
            placeholder="Select variables"
            value={selectedKeys}
            onChange={handleKeysChange}
            options={keys
              .filter((key) => key !== "Totals")
              .map((key) => ({
                value: key,
                label: key,
              }))}
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-semibold">Categories</label>
          <CustomSelect
            placeholder="Select categories"
            value={selectedCategories}
            onChange={handleCategoriesChange}
            options={data
              .filter((item) => item.xValue !== "Totals")
              .map((item) => ({
                value: item.xValue,
                label: item.xValue,
              }))}
          />
        </div>
        <DataQButton type="primary" onClick={exportChart}>
          Export <FontAwesomeIcon className="ml-2" icon={faChartColumn} />
        </DataQButton>
      </div>
      <div
        className="max-w-full overflow-auto"
        style={{ height: `${chartHeight}px` }}
        ref={chartRef}
      >
        <ResponsiveBar
          data={chartData}
          keys={[...selectedKeys, "Totals"]}
          indexBy="xValue"
          margin={{ top: 50, right: 130, bottom: 120, left: leftMargin }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id }) =>
            customColorScheme[keys.indexOf(id) % customColorScheme.length]
          }
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: xAxisLabel,
            legendPosition: "middle",
            legendOffset: 80,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: selectedStatistic,
            legendPosition: "middle",
            legendOffset: -leftMargin + 20,
            format: formatTickValue,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: "#333333",
                },
              },
              legend: {
                text: {
                  fill: "#333333",
                  fontSize: 14,
                },
              },
            },
            legends: {
              text: {
                fill: "#333333",
              },
            },
          }}
          tooltip={({ id, value, color, indexValue }) => (
            <CustomTooltip
              id={id}
              value={formatTickValue(value)}
              color={color}
              indexValue={indexValue}
            />
          )}
        />
      </div>
    </div>
  )
}
export default BarChart
